@import url("https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:wght@100;200;300;400;500;600;700&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  font-family: "IBM Plex Sans", sans-serif;

  scrollbar-width: thin;
  scrollbar-color: #9ca7b8;
}

a {
  color: inherit;
  text-decoration: none;
}

body::-webkit-scrollbar {
  width: 0.5rem;
}

body::-webkit-scrollbar-thumb {
  background: #9ca7b8;
}

/* body::-webkit-scrollbar {
  width: 0.5rem;
}

body::-webkit-scrollbar-track {
  background: var(--tertiary);
}

body::-webkit-scrollbar-thumb {
  background: var(--primary);
}  */
@layer base {
  input {
    @apply focus:ring-0 focus:outline-none !important;
  }
}
@layer utilities {
  .page-padding {
    @apply px-[30px] py-5;
  }
}

.custom-slick-dots {
  position: absolute;
  bottom: -25px;

  display: block;

  width: 100%;
  padding: 0;
  margin: 0;

  list-style: none;

  text-align: center;
}

.custom-slick-dots li {
  position: relative;

  display: inline-block;

  width: 20px;
  height: 20px;
  margin: 0 5px;
  padding: 0;

  cursor: pointer;
}

.custom-slick-dots li button {
  font-size: 0;
  line-height: 0;

  display: block;

  width: 20px;
  height: 20px;
  padding: 5px;

  cursor: pointer;

  color: transparent;
  border: 0;
  outline: none;
  background: transparent;
}

.custom-slick-dots li button:hover,
.custom-slick-dots li button:focus {
  outline: none;
}

.custom-slick-dots li button:hover::before,
.custom-slick-dots li button:focus::before {
  opacity: 1;
}

.custom-slick-dots li button::before {
  font-family: "slick";
  font-size: 6px;
  line-height: 20px;

  position: absolute;
  top: 0;
  left: 0;

  width: 20px;
  height: 20px;

  content: "•";
  text-align: center;

  opacity: 0.25;
  color: #f3641f;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.custmo-slick-dots li.slick-active button:before {
  opacity: 0.75;
  color: #f3641f;
}

.over {
  border: 2px dashed #666 !important;
}

@media all {
  .page-break {
    display: none;
  }
}

@media print {
  .page-break {
    margin-top: 0.5rem;
    display: block;
    page-break-before: auto;
  }
}

@page {
  size: auto;
  margin: 20mm;
}

#open-store .ant-switch-checked {
  background: #38c985 !important;
  color: white;
}

#store-status .ant-switch-checked {
  background: #06b2bc !important;
  color: white;
}
